import { zpMap } from '/js/zonpotentie_generic.js';
import { hespul } from '/js/hespul.js';

const debuggingEnabled = false;
const testenEnabled = false;

var map;
window.map;

var selectedObject;

var currentSelected;
window.currentSelected = currentSelected;

var entity
window.entity; 

/************************************************************************
*
* Custom objects
*
*************************************************************************/

const objectOrientation = {
  heading: 0,
  pitch: 0,
  roll: 0,
  scale: 0,
  height: 0
}

const arrow = {
  name: "Arrow",
  resource: "img/3D/arrow.glb",
  solar: false,
  defaultScale: 25.0,
  maximumScale: 100.0,
  adjustableHeight: true,
  adjustableHeading: true,
  adjustablePitch: true,
  adjustableScale: true,
  orientation: objectOrientation
}
window.arrow = arrow;

const solarPanel = {
  name: "SolarPanel",
  resource: "img/3D/solarpanel.glb",
  solar: true,
  defaultScale: 25.0,
  maximumScale: 100.0,
  adjustableHeight: true,
  adjustableHeading: true,
  adjustablePitch: true,
  adjustableScale: true,
  capacityWatt: 400,
  vermogensopbrengst: 85,
  orientation: objectOrientation
}
window.solarPanel = solarPanel;

const solarPanelVertical = {
  name: "SolarPanelVertical",
  resource: "img/3D/solarpanelVertical.glb",
  solar: true,
  defaultScale: 25.0,
  maximumScale: 100.0,
  adjustableHeight: true,
  adjustableHeading: true,
  adjustablePitch: true,
  adjustableScale: true,
  capacityWatt: 400,
  vermogensopbrengst: 85,
  orientation: objectOrientation
}
window.solarPanelVertical = solarPanelVertical;

const objectTree = {
  name: "ObjectTree",
  resource: "img/3D/tree.glb",
  defaultScale: 25.0,
  maximumScale: 100.0,
  adjustableHeight: false,
  adjustableHeading: true,
  adjustablePitch: false,
  adjustableScale: true,
  orientation: objectOrientation
}
window.objectTree = objectTree;

const appleTree = {
  name: "AppleTree",
  resource: "img/3D/appletree.glb",
  defaultScale: 25.0,
  maximumScale: 100.0,
  adjustableHeight: false,
  adjustableHeading: true,
  adjustablePitch: false,
  adjustableScale: true,
  orientation: objectOrientation
}
window.appleTree = appleTree;

const polyTree = {
  name: "PolyTree",
  resource: "img/3D/polytree.glb",
  defaultScale: 25.0,
  maximumScale: 100.0,
  adjustableHeight: false,
  adjustableHeading: true,
  adjustablePitch: false,
  adjustableScale: true,
  orientation: objectOrientation
}
window.polyTree = polyTree;

const polyPicea = {
  name: "PolyPicea",
  resource: "img/3D/polypicea.glb",
  defaultScale: 25.0,
  maximumScale: 100.0,
  adjustableHeight: false,
  adjustableHeading: true,
  adjustablePitch: false,
  adjustableScale: true,
  orientation: objectOrientation
}
window.polyPicea = polyPicea;

function mapObject(objectType, attributes, lon, lat) {
  this.objectType = objectType;
  this.attributes = attributes;
  this.lon = lon;
  this.lat = lat;
}
window.mapObject;

/************************************************************************
*
* Generic javascript utilities
*
*************************************************************************/

function test_message() {
  alert("Hello");
}
window.test_message = test_message;

function debug(message) {
  if (debuggingEnabled) {
    console.log (message);
  }
}

function setSelectedObject(pickedItem, position, entity) {

  selectedObject = {
    pickedItem,
    position,
    entity
  };

}

function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function GetPropertyValue(obj1, dataToRetrieve) {
  return dataToRetrieve
    .split('.') // split string based on '.'
    .reduce(function (o, k) {
      return o && o[k]; // get inner property if 'o' is defined else get 'o' and return
    }, obj1) // set initial value as object
}

function generateUUID() {
  var d = new Date().getTime();//Timestamp
  var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function callHint(hintTitle, hintText, imgSrc) {

  let cookieHint = getCookie("hint");
  debug("cookieHint: " + cookieHint);
  var isTrueSet = (cookieHint === 'true');
  if (isTrueSet) {
    return; // do nothing
  }

  // hint container
  let eHintContainer = document.createElement("div");
  eHintContainer.id = "eHintContainer";
  eHintContainer.className = "hint";
  eHintContainer.style.visibility = "visible";

  // title
  let eHintTitle = document.createElement("H1");
  eHintTitle.innerHTML = hintTitle;
  eHintContainer.appendChild(eHintTitle);

  // img
  let eImg = document.createElement("IMG");
  eImg.src = imgSrc;
  eImg.title = "hint";
  eHintContainer.appendChild(eImg);

  // text
  let eHintText = document.createElement("p");
  eHintText.className = "hintText";
  eHintText.innerHTML = hintText;
  eHintContainer.appendChild(eHintText);

  let eBegrepenButton = document.createElement("span");
  eBegrepenButton.innerHTML = "<a href='javascript:setBegrepen(eHintContainer);' class='btn navy'>Begrepen</a>"
  eBegrepenButton.id = "begrepen";
  eHintContainer.appendChild(eBegrepenButton);

  let eCheckboxContainer = document.createElement("div");
  let eCheckbox =  document.createElement("INPUT");
  let eCheckboxLabel = document.createElement("LABEL");
  eCheckbox.setAttribute("type", "checkbox");
  eCheckbox.id = "eCheckbox";
  eCheckboxContainer.className = "begrepen";
  eCheckboxLabel.setAttribute("for","eCheckbox");
  eCheckboxLabel.innerHTML = "<b>Begeleiding uitzetten  </b>";

  eHintContainer.appendChild(eCheckboxContainer);
  eCheckboxContainer.appendChild(eCheckboxLabel);
  eCheckboxContainer.appendChild(eCheckbox);

  document.body.appendChild(eHintContainer);

}

/************************************************************************
*
* Cesium utilities
*
*************************************************************************/

function toDegrees(cartesian3Pos) {
  let pos = Cesium.Cartographic.fromCartesian(cartesian3Pos)
  return [pos.longitude / Math.PI * 180, pos.latitude / Math.PI * 180]
}

/************************************************************************
*
* listening functions
*
*************************************************************************/

function listenEvent(eventObj, event, eventHandler) {
  if (eventObj.addEventListener) {
    eventObj.addEventListener(event, eventHandler, false);
  }
  else if (eventObj.attachEvent) {
    event = "on" + event;
    eventObj.attachEvent(event, eventHandler);
  }
  else {
    eventObj["on" + event] = eventHandler;
  }
}

function stopListening(eventObj, event, eventHandler) {
  if (eventObj.removeEventListener) {
    eventObj.removeEventListener(event, eventHandler, false);
  }
  else if (eventObj.detachEvent) {
    event = "on" + event;
    eventObj.detachEvent(event, eventHandler);
  }
  else {
    eventObj["on" + event] = null;
  }
}

function startListening() {

  var resize = window.addEventListener('resize', function(event) {
    debug ('resizing');
    resizeCanvas();
  }, true);

  var box = document.getElementById("id_adres");
  listenEvent(box, "keyup", refreshAdressList);

  var btnCurrentLocation = document.getElementById("currentLocation");
  listenEvent(btnCurrentLocation, "click", getCurrentLocation);

  var shadow = document.getElementById("id_schaduw");
  shadow.addEventListener('change', function () {
    shadowEnabling(map);
  });

  var rangeWatt = document.getElementById("sliderWatt");
  rangeWatt.addEventListener('input', function () {
    setWatt('ABS', rangeWatt.value);
  });
  
  var rangeVermogensOpbrengst = document.getElementById("sliderVermogensOpbrengst");
  rangeVermogensOpbrengst.addEventListener('input', function () {
    setVermogensOpbrengst('ABS', rangeVermogensOpbrengst.value);
  });

  var sliderVermogensOpbrengstDown = document.getElementById("sliderVermogensOpbrengstDown");
  sliderVermogensOpbrengstDown.addEventListener('click', function () {
    setVermogensOpbrengst('REL', (-1));
  });

  var sliderVermogensOpbrengstUp = document.getElementById("sliderVermogensOpbrengstUp");
  sliderVermogensOpbrengstUp.addEventListener('click', function () {
    setVermogensOpbrengst('REL', (1));
  });

  var rangeHeading = document.getElementById("sliderHeading");
  rangeHeading.addEventListener('input', function () {
    setOrientation("Heading", 'ABS', rangeHeading.value);
  });

  var rangePitch = document.getElementById("sliderPitch");
  rangePitch.addEventListener('input', function () {
    setOrientation("Pitch", 'ABS', rangePitch.value);
  });

  var rangeScale = document.getElementById("sliderScale");
  rangeScale.addEventListener('input', function () {
    setScale("ABS", rangeScale.value);
  });

  var rangeHeight = document.getElementById("sliderHeight");
  rangeHeight.addEventListener('input', function () {
    setHeight("ABS", rangeHeight.value);
  });

  var wattDown = document.getElementById("sliderWattDown");
  wattDown.addEventListener('click', function () {
    setWatt('REL', (-50));
  });

  var wattUp = document.getElementById("sliderWattUp");
  wattUp.addEventListener('click', function () {
    setWatt('REL', (50));
  });

  var headingUp = document.getElementById("sliderHeadingUp");
  headingUp.addEventListener('click', function () {
    setOrientation("Heading", 'REL', (1));
  });

  var headingDown = document.getElementById("sliderHeadingDown");
  headingDown.addEventListener('click', function () {
    setOrientation("Heading", 'REL', (-1));
  });

  var headingUp = document.getElementById("sliderHeadingUp");
  headingUp.addEventListener('click', function () {
    setOrientation("Heading", 'REL', (1));
  });

  var pitchDown = document.getElementById("sliderPitchDown");
  pitchDown.addEventListener('click', function () {
    setOrientation("Pitch", 'REL', (-1));
  });

  var pitchUp = document.getElementById("sliderPitchUp");
  pitchUp.addEventListener('click', function () {
    setOrientation("Pitch", 'REL', (1));
  });

  var scaleDown = document.getElementById("sliderScaleDown");
  scaleDown.addEventListener('click', function () {
    setScale('REL', (-1));
  });

  var scaleUp = document.getElementById("sliderScaleUp");
  scaleUp.addEventListener('click', function () {
    setScale('REL', (1));
  });

  var heigtDown = document.getElementById("sliderHeightDown");
  heigtDown.addEventListener('click', function () {
    setHeight('REL', (-0.1));
  });

  var heightUp = document.getElementById("sliderHeightUp");
  heightUp.addEventListener('click', function () {
    setHeight('REL', (0.1));
  });

  var rangeMonth = document.getElementById("sliderMonth");
  rangeMonth.addEventListener('input', function () {
    setMonth(rangeMonth);
  });

  var cookieAgree = document.getElementById("cookieAgree");
  cookieAgree.addEventListener('click', function () {
    setCookieWaarde();
  });

  var objecten = document.getElementById("objectenMenu");
  objecten.addEventListener('click', function () {
    openObjectenMenu(this);
  });

  var overZonpotentie = document.getElementById("overZonpotentieMenu");
  overZonpotentie.addEventListener('click', function () {
    openOverZonpotentieMenu(this);
  });

  var simulaties = document.getElementById("simulatiesMenu");
  simulaties.addEventListener('click', function () {
    openSimulalatiesMenu(this);
  });

  var modalImage = document.getElementById("simulatieZonnepaneel");
  modalImage.addEventListener('click', function () {
    openModalImage(this);
  });

}

function stopClick() {
  var box = document.getElementById("id_adres");
  stopListening(box, "keyup", refreshAdressList);
}

listenEvent(window, "load", function () {
  startListening();
});

/************************************************************************
*
* onload
*
*************************************************************************/

window.onload = function () {

  var sliderMonth = document.getElementById("sliderMonth");
  sliderMonth.value = 1;

  var date = new Date("2023/01/01");
  var sliderDate = document.getElementById("sliderDatum");

  var min_val = Date.parse("2023/01/01 00:00:00") / 1000;
  var max_val = Date.parse("2023/01/31 00:00:00") / 1000;

  sliderDate.min = min_val;
  sliderDate.max = max_val;

  sliderDate.value = min_val;
};

/************************************************************************
*
* zonpotentie functions
*
*************************************************************************/

function setEfficiency(customObject) {

  var isTrueSet = (customObject.customAttributes.solar === true);
  if (isTrueSet) {

    var heading = customObject.customAttributes.orientation.heading;
    var pitch = customObject.customAttributes.orientation.pitch; //Math.abs(customObject.customAttributes.orientation.pitch);

    debug("heading: " + heading);
    debug("pitch: " + (pitch));

    if (heading >= 90 && heading <= 270) {
      debug("solar orientated south");
      roundedHeading = 0;
      if (pitch > 0) {
        // pitch begrenzen op 0
        roundedPitch = -0;
      } else {
        var roundedPitch = Math.abs(Math.round(pitch / 5) * 5);
        var roundedHeading = Math.round(heading / 5) * 5;
      }
    } else {
      debug("solar orientated north");
      if (pitch < 0) {
        // pitch begrenzen op 0
        roundedPitch = 90;
      } else {
        var roundedPitch = Math.round(pitch / 5) * 5;
        var roundedHeading = (180 + Math.round(heading / 5) * 5);
      }
    } 

    debug("roundedHeading: " + roundedHeading);
    debug("roundedPitch: " + roundedPitch);

    // first pitch, then heading
    var hespulValue = hespul[roundedPitch][roundedHeading];
    debug("HespulValue: " + hespulValue);

    if (hespulValue) {
      afwijkingOptimaleOrientatie.innerHTML = hespulValue;
    } else {
      afwijkingOptimaleOrientatie.innerHTML = 0;
    }

  }
}

function showHideControls(customObject) {

  debug("showHideControls (+)");

  var rgnWatt = document.getElementById("rgnWatt");
  var isTrueSet = (customObject.customAttributes.solar === true);
  if (isTrueSet) {
    rgnWatt.style.display = "inline";
  } else {
    rgnWatt.style.display = "none";
  }

  var rgnVermogensopbrengst = document.getElementById("rgnVermogensopbrengst");
  var isTrueSet = (customObject.customAttributes.solar === true);
  if (isTrueSet) {
    rgnVermogensopbrengst.style.display = "inline";
  } else {
    rgnVermogensopbrengst.style.display = "none";
  }

  var rgnHeight = document.getElementById("rgnHeight");
  var isTrueSet = (customObject.customAttributes.adjustableHeight === true);
  if (isTrueSet) {
    rgnHeight.style.display = "inline";
  } else {
    rgnHeight.style.display = "none";
  }

  var rgnHeading = document.getElementById("rgnHeading");
  var isTrueSet = (customObject.customAttributes.adjustableHeading === true);
  if (isTrueSet) {
    rgnHeading.style.display = "inline";
  } else {
    rgnHeading.style.display = "none";
  }

  var rgnPitch = document.getElementById("rgnPitch");
  var isTrueSet = (customObject.customAttributes.adjustablePitch === true);
  if (isTrueSet) {
    rgnPitch.style.display = "inline";
  } else {
    rgnPitch.style.display = "none";
  }

  var rgnScale = document.getElementById("rgnScale");
  var isTrueSet = (customObject.customAttributes.adjustableScale === true);
  if (isTrueSet) {
    rgnScale.style.display = "inline";
  } else {
    rgnScale.style.display = "none";
  }

  var rgnCalculation = document.getElementById("rgnCalculation");
  var isTrueSet = (customObject.customAttributes.solar === true);
  if (isTrueSet) {
    rgnCalculation.style.display = "inline";
  } else {
    rgnCalculation.style.display = "none";
  }

  debug("showHideControls (-)");
}
window.showHideControls = showHideControls;

function HideControls() {

  debug("HideControls (+)");

  var rgnWatt = document.getElementById("rgnWatt");
  rgnWatt.style.display = "none";

  var rgnVermogensopbrengst = document.getElementById("rgnVermogensopbrengst");
  rgnVermogensopbrengst.style.display = "none";

  var rgnHeight = document.getElementById("rgnHeight");
  rgnHeight.style.display = "none";

  var rgnHeading = document.getElementById("rgnHeading");
  rgnHeading.style.display = "none";

  var rgnPitch = document.getElementById("rgnPitch");
  rgnPitch.style.display = "none";

  var rgnScale = document.getElementById("rgnScale");
  rgnScale.style.display = "none";

  var rgnCalculation = document.getElementById("rgnCalculation");
  rgnCalculation.style.display = "none";

  debug("HideControls (-)");
}
window.HideControls = HideControls;

function refreshAdressList() {
  addressSuggest();
}

function setCookieWaarde() {
  var pageCookie = document.getElementById("pagecookie");
  pageCookie.style.visibility = "hidden";

  setCookie("cookieAgree", true, 30);
}

function openObjectenMenu(e) {
  debug ('openObjectenMenu (+)');

  var content = e.nextElementSibling;
    if (content.style.maxHeight){
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = "300px";
      content.style.overflowY = 'auto';
    } 

    debug ('openObjectenMenu (-)');
}

function openOverZonpotentieMenu(e) {
  debug ('openOverZonpotentieMenu (+)');

  var content = e.nextElementSibling;
    if (content.style.maxHeight){
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = "300px";
      content.style.overflowY = 'auto';
    } 

    debug ('openOverZonpotentieMenu (-)');
}

function openSimulalatiesMenu(e) {
  debug ('openSimulalatiesMenu (+)');

  var content = e.nextElementSibling;
    if (content.style.maxHeight){
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = "300px";
      content.style.overflowY = 'auto';
    } 

    debug ('openSimulalatiesMenu (-)');
}

function openModalImage(e) {
  debug('openModalImage (+)');

  // Get the modal
  var modal = document.getElementById("myModal");

  // Get the image and insert it inside the modal - use its "alt" text as a caption
  var modalImg = document.getElementById("modalScreenshot");
  var captionText = document.getElementById("caption");
 
  modal.style.display = "block";
  modalImg.src = e.src; //'/img/2D/simulatieZonnepaneel.jpg'; //e.src;
  captionText.innerHTML = e.alt;

  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName("close")[0];

  // When the user clicks on <span> (x), close the modal
  span.onclick = function () {
    modal.style.display = "none";
  }
  debug('openModalImage (-)');
}

function setBegrepen(e) {

  debug ("setBegrepen (+)");

  debug (e);
  var chkHint = document.getElementById("eCheckbox").checked;
  debug("waarde: " + chkHint);
  setCookie("hint", chkHint, 30);

  let el = document.getElementById("eHintContainer");
  debug (el);
  el.parentElement.removeChild(el);

  debug ("setBegrepen (-)");
}
window.setBegrepen = setBegrepen;

function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  // get month in local language
  return date.toLocaleString([], {
    month: 'long',
  });
}

function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

function formatDT(datum) {
  var year = datum.getFullYear();
  var month = zeroPad(datum.getMonth() + 1, 2);
  var date = datum.getDate();
  var hours = zeroPad(datum.getHours(), 2);
  var minutes = zeroPad(datum.getMinutes(), 2);
  return date + ' ' + getMonthName(month) + ' - ' + hours + ':' + minutes;
};

function shadowEnabling(e) {

  debug('shadowEnabling (+)');

  var shadowEnabled = document.getElementById("id_schaduw").checked;
  debug('shadowEnabled: ' + shadowEnabled);

  if (shadowEnabled === false) {
    map.viewer.scene.globe.enableLighting = false;
    map.viewer.shadows = false;
  } else {
    map.viewer.scene.globe.enableLighting = true;
    map.viewer.shadows = true;
  }

  debug('shadowEnabling (-)');
}

function timeSlider(e, dateValue) {

  debug('timeSlider (+)');

  var date = new Date(dateValue * 1000);
  debug('Datum: ' + date);

  let year = date.getFullYear();
  debug('year: ' + year);
  let month = date.getMonth();
  debug('month: ' + month);
  let day = date.getDate();
  debug('day: ' + day);
  let hour = date.getHours();
  debug('hour: ' + hour);

  var currentTime = Cesium.JulianDate.fromDate(new Date(year, month, day, hour));
  var endTime = Cesium.JulianDate.addDays(currentTime, 1, new Cesium.JulianDate());
  e.viewer.clock.currentTime = currentTime;

  debug('timeSlider (-)');
}

function setMonth(slider) {

  debug('setMonth (+)');

  /* calculate tooltip control position */
  let relativeSliderPos = Number((slider.value - slider.min) * 100 / (slider.max - slider.min));
  debug('newValue: ' + relativeSliderPos);
  let newPosition = 30 - (relativeSliderPos * 0.32);
  debug('newPosition: ' + newPosition);

  tooltipMaand.innerHTML = '<span>' + getMonthName(slider.value) + '</span>';
  tooltipMaand.style.left = `calc(${relativeSliderPos}% + (${newPosition}px))`;

  var month = slider.value;

  /* inititialize sliderDatum */
  var sliderDate = document.getElementById("sliderDatum");

  let currentYear = new Date().getFullYear();
  debug("currentYear: " + currentYear);
  debug("Month: " + month);

  var dt_from = currentYear + "/" + month + "/01 00:00:00";
  var dt_to = currentYear + "/" + month + "/31 23:59:00";

  var min_val = Date.parse(dt_from) / 1000;
  var max_val = Date.parse(dt_to) / 1000;

  sliderDate.min = min_val;
  sliderDate.max = max_val;

  /* reset de tooltipDatum elke keer nadat men een maand heeft gekozen */
  var date = new Date(min_val * 1000);
  tooltipDatum.innerHTML = '<span>' + formatDT(date) + '</span>';
  //tooltipDatum.style.left = '4%';
  sliderDate.value = 1;

  debug('setMonth (-)');

}

async function addressSuggest(elem) {

  debug('address_suggest (+)');

  var list = document.getElementById("resultaten_container");
  list.innerHTML = '';

  var search_value = document.getElementById("id_adres").value;

  // starting searching when > 3 characters have been entered
  if (search_value.length > 2) {

    list.style.display = "block";

    let response = await fetch('https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=' + search_value);
    let myJson = await response.json();
    debug(myJson);

    var ul = document.createElement('ul');
    ul.setAttribute('id', 'adressen');

    document.getElementById('resultaten_container').appendChild(ul);

    for (const key in myJson.response.docs) {
      debug(myJson.response.docs[key].type);
      debug(myJson.response.docs[key].weergavenaam);
      var li = document.createElement('li');
      li.setAttribute('id', myJson.response.docs[key].id);
      li.setAttribute('class', 'item');

      ul.appendChild(li);

      var addresId = myJson.response.docs[key].id;
      var a = document.createElement("a");
      a.href = "javascript: getAddress('" + addresId + "');";
      a.textContent = myJson.response.docs[key].weergavenaam;
      li.appendChild(a);
    }
  } else {
    list.style.display = "none";
  }

  debug('addressSuggest (-)');
}

async function getAddress(p_id) {

  debug('getAddress (+)');

  debug('p_id: ' + p_id);
  var getAddressUrl = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/lookup?id=' + p_id;

  let response = await fetch(getAddressUrl);
  let myJson = await response.json();
  debug(myJson);

  debug('adres coordinates');
  debug(myJson.response.docs[0].centroide_ll);
  let bm = await rebuildMap(myJson.response.docs[0].centroide_ll);

  var list = document.getElementById("resultaten_container");
  list.style.display = "none";

  debug('getAddress (-)');
}
window.getAddress = getAddress;

function getCoordinates(position) {
    let coordinates = 'POINT(' + position.coords.longitude + ' ' +position.coords.latitude  + ')';
    debug ('coordinates: ' + coordinates);
    let bm = rebuildMap(coordinates);
    spinner.style.visibility = 'hidden';
}

async function getCurrentLocation() {
  debug('getCurrentLocation (+)');

  if (navigator.geolocation) {

    spinner.style.visibility = 'visible';
    navigator.geolocation.getCurrentPosition(getCoordinates);
    
  } else { 
    alert ("Geolocation is not supported by this browser.");
  }

  debug('getCurrentLocation (-)');
}

function addObject(mapObject) {

  debug("addObject (+)");

  (async () => {
    try {

      debug("mapObject.objectType: " + mapObject.objectType);
      let objectAttributes;

      debug(mapObject.attributes);
      objectAttributes = JSON.parse(mapObject.attributes);
      debug(objectAttributes);

      map.viewer.scene.globe.depthTestAgainstTerrain = true;

      debug('mapObject.lon: ' + mapObject.lon);
      debug('mapObject.lat: ' + mapObject.lat);
      const position = Cesium.Cartesian3.fromDegrees(mapObject.lon, mapObject.lat, 0);
      debug("Cartesian3 object position: " + position);

      debug("objectAttributes.heading: " + objectAttributes.heading);
      debug("objectAttributes.pitch: " + objectAttributes.pitch);
      debug("objectAttributes.roll: " + objectAttributes.roll);

      const hpr = new Cesium.HeadingPitchRoll(objectAttributes.heading, objectAttributes.pitch, objectAttributes.roll);
      const orientation = Cesium.Transforms.headingPitchRollQuaternion(
        position,
        hpr
      );

      // add entity with default values
      entity = map.viewer.entities.add({
        position: position,
        orientation: orientation,
        allowPicking: true,
        zIndex: 2,
        enableCollisionDetection: false,
        model: {
          uri: objectAttributes.resource,
          classificationType: Cesium.HeightReference.TERRAIN,
          disableDepthTestDistance: true,
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
          scale: objectAttributes.defaultScale,
          maximumScale: objectAttributes.maximumScale,
          zIndex: 2,
        },
        properties: {
          entityType: mapObject.objectType,
          id: generateUUID(),
        }
      });

      entity.customAttributes = new Cesium.ConstantProperty(true);
      entity.customAttributes = objectAttributes;

      debug("created entity: ");
      debug(entity);

      // set selected
      debug("set selected immediately");
      map.viewer.selectedEntity = entity;
      const pickedItem = map.viewer.scene.pick(position);

      selectedObject = {
        pickedItem,
        position,
        entity
      };

      // show or hide controls based on object
      showHideControls(map.viewer.selectedEntity);

    } catch (error) {
      debug(error);
    }
  })();

  debug('addObject (-)');
}
window.addObject = addObject;

function setOrientation(orientationMethod, indicationAbsRel, sliderValue) {

  debug("setOrientation (+)");

  debug("orientationMethod: " + orientationMethod);
  debug("Absolute or Relative value: " + indicationAbsRel);
  debug("Value: " + sliderValue);

  if (selectedObject) {

    entity = GetPropertyValue(selectedObject, "entity");
    debug("entity: " + entity);
    debug(entity);

    debug("current entity orientation: " + entity.orientation);

    const currentTime = map.viewer.clock.currentTime;
    var scratch3dPosition = new Cesium.Cartesian3();
    var position3d = entity.position.getValue(currentTime, scratch3dPosition);

    debug("Cartesion position: " + position3d);

    let currentHPR = Cesium.HeadingPitchRoll.fromQuaternion(entity.orientation.getValue(currentTime));
    debug("current HPR: " + currentHPR);
    debug("current Heading: " + currentHPR.heading);
    debug("current Pitch: " + currentHPR.pitch);
    debug("current Roll: " + currentHPR.roll);

    let newHeading;
    let newPitch;
    let newRoll = 0; // always 0
    let newValue;

    if (orientationMethod == "Heading") {
      if (indicationAbsRel == "ABS") {
        newValue = sliderValue;
        newHeading = Cesium.Math.toRadians(newValue);
      } else {
        newValue = (Number(entity.customAttributes.orientation.heading) + Number(sliderValue));
        newHeading = Cesium.Math.toRadians(newValue);
      }
      newPitch = Cesium.Math.toRadians(entity.customAttributes.orientation.pitch);
      //store value
      entity.customAttributes.orientation.heading = newValue;
    }

    if (orientationMethod == "Pitch") {
      if (indicationAbsRel == "ABS") {
        newValue = sliderValue;
        newPitch = Cesium.Math.toRadians(newValue);
      } else {
        newValue = (Number(entity.customAttributes.orientation.pitch) + Number(sliderValue));
        newPitch = Cesium.Math.toRadians(newValue);
      }
      newHeading = Cesium.Math.toRadians(entity.customAttributes.orientation.heading);
      //store value
      entity.customAttributes.orientation.pitch = newValue;
    }

    const newHPR = new Cesium.HeadingPitchRoll(newHeading, newPitch, newRoll);
    const newOrientation = Cesium.Transforms.headingPitchRollQuaternion(
      position3d,
      newHPR
    );

    debug("new HPR: " + newHPR);
    debug("new Heading: " + newHPR.heading);
    debug("new Pitch: " + newHPR.pitch);
    debug("new Roll: " + newHPR.roll);
    debug("new entity orientation: " + newOrientation);

    sliderHeadingValue.innerHTML = entity.customAttributes.orientation.heading;
    sliderPitchValue.innerHTML = entity.customAttributes.orientation.pitch;

    entity.orientation = newOrientation;

    // calculate efficiency
    setEfficiency(entity);

  }

  debug("setOrientation (-)");
};

function setScale(indicationAbsRel, sliderValue) {

  debug("setScale (+)");
  debug("Absolute or Relative value: " + indicationAbsRel);
  debug("Value: " + sliderValue);

  var newValue;

  if (selectedObject) {
    entity = GetPropertyValue(selectedObject, "entity");
    debug("entity: ");
    debug(entity);

    if (indicationAbsRel == "ABS") {
      newValue = sliderValue;
    } else {
      newValue = (Number(entity.model.scale) + Number(sliderValue));
    }

    sliderScaleValue.innerHTML = newValue;
    entity.model.scale = newValue;

    //store value
    entity.customAttributes.orientation.scale = newValue;
  }

  debug("setScale (-)");
}

function setHeight(indicationAbsRel, sliderValue) {

  debug("setHeight (+)");
  debug("Absolute or Relative value: " + indicationAbsRel);
  debug("Value: " + sliderValue);

  var newValue;

  if (selectedObject) {
    entity = GetPropertyValue(selectedObject, "entity");
    debug("entity: " + entity);

    let position = GetPropertyValue(selectedObject, "position");
    debug("slider position: " + position);

    var scratch3dPosition = new Cesium.Cartesian3();

    var position3d = entity.position.getValue(map.viewer.clock.currentTime, scratch3dPosition);
    debug("position3d: " + position3d);

    let cartographic = Cesium.Cartographic.fromCartesian(position3d);
    let lng = Cesium.Math.toDegrees(cartographic.longitude);
    let lat = Cesium.Math.toDegrees(cartographic.latitude);
    debug("cartographic.height: " + cartographic.height);
    let alt = parseFloat(cartographic.height).toFixed(1);
    debug("height: " + alt);

    if (indicationAbsRel == "ABS") {
      newValue = sliderValue;
    } else {
      newValue = (Number(alt) + Number(sliderValue));
    }

    alt = round(newValue, 1);

    debug("lng: " + lng);
    debug("lat: " + lat);
    debug("alt: " + alt);

    var newposition = Cesium.Cartesian3.fromDegrees(lng, lat, alt);

    sliderHeightValue.innerHTML = alt;

    entity.position = newposition;

    //store value
    entity.customAttributes.orientation.height = alt;
  }

  debug("setHeight (-)");
}

function setWatt(indicationAbsRel, sliderValue) {

  debug("setWatt (+)");
  debug("Absolute or Relative value: " + indicationAbsRel);
  debug("Value: " + sliderValue);

  var newValue;

  if (selectedObject) {
    entity = GetPropertyValue(selectedObject, "entity");
    debug("entity: " + entity);

    if (indicationAbsRel == "ABS") {
      newValue = sliderValue;
    } else {
      newValue = (Number(entity.customAttributes.capacityWatt) + Number(sliderValue));
    }

    sliderWattValue.innerHTML = newValue;

    //store value
    entity.customAttributes.capacityWatt = newValue;
  }

  debug("setWatt (-)");
}

function setVermogensOpbrengst(indicationAbsRel, sliderValue) {

  debug("setVermogensOpbrengst (+)");
  debug("Absolute or Relative value: " + indicationAbsRel);
  debug("Value: " + sliderValue);

  var newValue;

  if (selectedObject) {
    entity = GetPropertyValue(selectedObject, "entity");
    debug("entity: " + entity);

    if (indicationAbsRel == "ABS") {
      newValue = sliderValue;
    } else {
      newValue = (Number(entity.customAttributes.vermogensopbrengst) + Number(sliderValue));
    }

    sliderVermogensOpbrengstValue.innerHTML = newValue;

    //store value
    entity.customAttributes.vermogensopbrengst = newValue;
  }

  debug("setVermogensOpbrengst (-)");
}

async function Start() {

  debug('Start (+)');

  map = new zpMap();
  
  var tileLayers =  data;

  callHint("Hallo!", "Eerste keer op deze website?<br>We helpen je even op weg.<br><br>Zoek eerst een adres<br>", "img/2D/muis.png");

  let cookieAgree = getCookie("cookieAgree");
  debug("cookieAgree: " + cookieAgree);
  var isTrueSet = (cookieAgree === 'true');
  if (isTrueSet) {
    null; // do nothing
  } else {
    pagecookie.style.visibility = "visible";
  }

  let selectedItemId = null;

  var geoJson = {
    type: "FeatureCollection",
    name: "Trees",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            4.774394325, 51.581682074
          ]
        },
        properties: {
          entityType: "Marker",
          marker_id: "19284371982379123"
        }
      }
    ]
  };

  geoJson = {}

  var {
    config: defaultConfig,
    layerConfig: defaultLayerConfig,
  } = map;

  const config = Object.assign({}, defaultConfig, {
    target: "map",
    cluster: {
      pixelRange: 1000,
      minimumClusterSize: 2,
      disableDepthTestDistance: false,
    },
    
    tilelayers: tileLayers
    
    //tilelayers: [
    //  {
    //  name: "NL758",
    //  crossOrigin: null,
    //  url: "http://localhost:5173/tiles/tileset.json"
    //  }
    //  //{
    //  //name: "NL758",
    //  //crossOrigin: null,
    //  //url: "www.zonpotentie.nl/js/Tilesets.json"
    //  //}
    //  //{
    //  //name: "NL758",
    //  //crossOrigin: null,
    //  //url: "https://zonpotentie.nl/tiles/758.json"
    //  //}      
   // ]
    ,
    zoom: {
      zoomOnClick: true,
      zoomDistancePoint: 500,
      zoomDistanceCluster: 1000,
      minimumZoomDistance: 1,
    },
    onClick: async (id, properties) => {
      if (selectedItemId) await map.deselectItem(selectedItemId)
      selectedItemId = id;
      debug('New map Onclick event: ' + id, properties);
    },
  });

  map.activate(config);

  debug('Set default view (+)');

  if (testenEnabled) {
    // set center Breda
    //map.viewer.scene.globe.enableLighting = true;
    map.viewer.scene.camera.setView({
      destination: new Cesium.Cartesian3.fromDegrees(
        4.774394325,
        51.581682074,
        200,
      ),
      orientation: {
        heading: Cesium.Math.toRadians(0.0),
        pitch: Cesium.Math.toRadians(-45.0),
        roll: 0.0,
      },
    });
  } else {
    // set center Utrecht
    debug("set center Utrecht");
    //map.viewer.scene.globe.enableLighting = true;
    map.viewer.scene.camera.setView({
      destination: new Cesium.Cartesian3.fromDegrees(
        5.12222,
        52.09083,
        10000000
      ),
      orientation: {
        heading: Cesium.Math.toRadians(0.0),
        pitch: Cesium.Math.toRadians(-90.0),
        roll: 0.0,
      },
    });
  }
  debug('Set default view (-)');

  
  var datasetName = "example";
  var idProperty = "gml_id";

  var layerConfig = Object.assign({}, defaultLayerConfig, {
    markerColor: "#168838",
    markerSymbol: map.icons["circle-stroked"],
  });

  var slider = document.getElementById("sliderDatum");
  slider.oninput = function () {
    var date = new Date(this.value * 1000);

    let newValue = Number((slider.value - slider.min) * 100 / (slider.max - slider.min));
    let newPosition = 30 - (newValue * 0.32);
    let tooltip = document.getElementById('tooltipDatum');
    tooltip.innerHTML = '<span>' + formatDT(date) + '</span>';
    tooltip.style.left = `calc(${newValue}% + (${newPosition}px))`;

    { timeSlider(map, this.value) }
  }

  const shadowMap = map.viewer.shadowMap;
  shadowMap.size = 4096;
  shadowMap.darkness = 0.4;
  //shadowMap.softShadows = true;

  map.viewer.selectedEntityChanged.addEventListener((entity) => {
    debug('selectedEntityChanged (+)');
    debug(entity);

    //const feature = entity.feature;
    //debug(Cesium.defined(feature));

    // only execute in case of buildings (which have features)
    //if (Cesium.defined(feature)) {
      try {
        if (currentSelected) {
          debug('currentSelected');
          // reset color of the current selected if available:
          const { entity, color } = currentSelected;

          //if (Cesium.defined(feature)) {
          //  debug ('in conditie');
          entity.feature.color = color;

          //}
          // unselect current selection:
          currentSelected = null;
        }

        // is there a new entity?
        if (Cesium.defined(entity)) {
          debug('new entity');
          // yes, save this entity AND its color:
          currentSelected = {
            entity,
            color: entity.feature.color,
          };

          //if (Cesium.defined(feature)) {
            // and change the current color to purple:
            entity.feature.color = Cesium.Color.PURPLE.withAlpha(1.0);
          //}

        }
      } catch (e) {
        debug('Error: ' + e);
      }
    //}

    debug('selectedEntityChanged (-)');
  });


  document.addEventListener("DOMContentLoaded", function () {
    debug('DOMContentLoaded listener');
  });

  debug('Start (-)');
}
window.Start = Start;

async function rebuildMap(geopos) {

  debug('rebuildMap (+)');

  debug('geopos: ' + geopos);
  var long = geopos.substring(geopos.indexOf('(') + 1);
  long = long.substring(0, long.indexOf(' '));
  debug('Longtitude: ' + long);

  var lat = geopos.substring(geopos.indexOf(' ') + 1);
  lat = lat.substring(0, lat.indexOf(')'));
  debug('Latitude: ' + lat);

  // initialize geoJson object with empty coordinates
  var geoJson = {
    type: "FeatureCollection",
    name: "Trees",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: []
        },
        properties: {
          entityType: "Marker",
          gml_id: "19284371982379123"
        }
      }
    ]
  };

  //push coordinates   
  geoJson.features[0].geometry.coordinates.push(long, lat);

  const layerConfig = Object.assign({}, map.defaultLayerConfig, {
    markerColor: "#168838",
    markerSymbol: "warehouse", //map.icons["bus"],
  });

  const datasetName = "example";
  const idProperty = "gml_id";

  const layer = await map.addLayer(
    datasetName,
    idProperty,
    geoJson,
    layerConfig,
  );

  callHint("Mooi!", "Klik op het groene icoontje om in te zoomen", "img/2D/marker_icon.png");

  debug('rebuildMap (-)');
}

export { Start, map, selectedObject, setSelectedObject, mapObject, addObject, getAddress, test_message, debug, showHideControls, HideControls, callHint};